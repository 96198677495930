//fonts
$font_0: Lato;
$font_1: sans-serif;
$font_2: Philosopher;
$font_3: Cormorant;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;
$font-work-sans: work-Sans;
$font-nunito: Nunito;
$font-serif: sans-serif;

:root {
    --theme-color: #ff4c3b;
}

//colors
$gray-90: #e5e5e5;
$white: #ffffff;
$white-dark: #eeeeee;
$black: #000000;
$grey01:#111111;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color:#333333;
$grey: #777777;
$grey-light: #a1a1a1;
$border:#dddada;
$round-border: #dddddd;
$dark-grey: #565656;
$dark-silver: #909090;
$grey-lighter: #f9f9f9;
$theme-deafult: #ff4c3b;
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g:#525252;
$top-header: #f8f8f8;
$header-font: #999999;
$shadow: #ededed;
$box-border: #ecececa8;
$grey-about: #f7f7f7;
$grey2:#555555;
$grey3: #efefef;
$silver-light-shade: #ced4da;
$grey4: #444444;
$grey5: #888888;
$grey6:#6f6f6f;
$grey7:#40494f;
$grey8:#c0c0c0;
$sidebar-border: #f5f2f2;
$sidebar-color:#938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$pink: #f1e7e6;
$blue: #bfbfbf;
$icon: #6f6f6f;
$color-red:#ff4c3b;
$gradient1: #01effc;
$gradient2: #485ff2;
$gray: #bbbbbb;
$left-sidebar: #2b2b2b;
$skin-color: #f8efe7;

// dark layout variable //
$dark-body:#2b2b2b;
$dark-top:#232323;
$dark-border:#404040;
$dark-link:#cbcbcb;
$dark-span:#929292;
$dark-footer-bg:#383838;
$white-1:#f1f3f5;
$white-2:#eaedef;
$white-3:#e7eaec;
$white-4:#dee2e6;
$white-5:#cfd4da;

// skeleton loader colors
$bg-loader: #f3f3f3;

// Theme colors variables
$primary-color: #ff8084;
$secondary-color: #13c9ca;
$success-color: #81ba00;
$info-color : #00a8ff;
$warning-color: #ffbc58;
$danger-color: #a5a5a5;
$light-color : #f8f8f9;
$semi-dark :#aaaaaa;
$light-semi-gray :#eff0f1;
$light-gray :#e8ebf2;
$dark-gray:#898989;
$dark-color : #2a3142;
$gray-60 :#999999;
$transparent-color: transparent;
$auth-bg-color:#fafafa;
$light : #f6f6f6;
$light-text: #999;

