/**=====================
    instagram css start
==========================**/

.slick-instagram {
    margin-bottom: -5px;
}

.insta-title {
    position: absolute;
    height: 45px;
    width: 277px;
    background-color: $white;
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
        margin-bottom: 0;
        text-transform: capitalize;
        font-weight: 600;

        span {
            color: var(--theme-color);
        }
    }
}

.instagram-box {
    position: relative;
    background-color: var(--theme-color);
    overflow: hidden;

    img {
        width: 100%;
    }

    .overlay {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: var(--theme-color);
        top: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(3) rotate(90deg);

        i {
            font-size: 60px;
            color: $white;
        }
    }

    &:hover {
        .overlay {
            opacity: 0.5;
            transition: 0.5s ease;
            transform: scale(1) rotate(0deg);
        }
    }
}


.insta-style {
    .slick-current {
        .instagram-box {
            .overlay {
                opacity: 0.4;
                transform: scale(1) rotate(0deg);
            }
        }
    }
}